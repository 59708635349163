<template>
	<v-layout column fill-height>
		<v-flex scroll-y>
			<v-layout px-3 row wrap>
				<v-flex xs12>
					<v-textarea v-model="copyCompany.details.note" auto-grow color="primary" :label="$t('customer.notes.note')" />
				</v-flex>
			</v-layout>
			<v-layout justify-end px-3 row>
				<v-flex shrink>
					<v-btn color="primary" flat round @click="save">{{ $t('actions.save') }}</v-btn>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/CustomersManager/CustomersManagerModuleGuard'

export default {
	name: 'CustomerDetailsNotes',
	mixins: [CustomersManagerModuleGuard],
	props: {
		company: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			copyCompany: { details: {} }
		}
	},
	watch: {
		company: {
			deep: true,
			handler: function (val) {
				this.copyCompany = { ...val }
				if (!this.copyCompany.details) {
					this.copyCompany.details = {}
				}
			},
			immediate: true
		}
	},
	methods: {
		save: function () {
			this.service.updateCompanyDetails(this.accountingFirmId, this.company.id, this.copyCompany).then(() => {
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('client_workspace.details.data_updated'))
			})
		}
	}
}
</script>
